import { render, staticRenderFns } from "./ColorCardManageDetails.vue?vue&type=template&id=d9a51cfc&"
import script from "./ColorCardManageDetails.vue?vue&type=script&lang=js&"
export * from "./ColorCardManageDetails.vue?vue&type=script&lang=js&"
import style0 from "./ColorCardManageDetails.vue?vue&type=style&index=0&id=d9a51cfc&prod&lang=less&scope=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "ColorCardManageDetails.vue"
export default component.exports